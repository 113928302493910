.red {
  color: #c43d27; }

.b-red {
  background-color: #c43d27;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75); }

.grey {
  color: #b2af9a; }

.b-grey {
  background-color: #b2af9a;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75); }

.blue {
  color: #07bed6; }

.b-blue {
  background-color: #07bed6;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75); }

body {
  font-family: "Architects Daughter", cursive;
  background-color: #fff; }

.header {
  margin: 15px auto auto;
  display: block;
  width: 450px; }
  .header .imageLogo {
    height: 100px;
    width: 100px;
    float: left; }
  .header .title {
    font-size: 50px;
    font-weight: bold;
    line-height: 100px;
    margin-left: 115px; }

.legenda-blue,
.legenda-red {
  color: #fff;
  width: 250px;
  margin: 25px auto 0 auto;
  border-radius: 100% 100% 0 0;
  height: 30px;
  text-align: center;
  padding-top: 10px;
  font-size: 20px; }

.legenda-blue {
  background-color: #07bed6; }

.legenda-red {
  background-color: #c43d27; }

.block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  display: block;
  text-align: center;
  line-height: 100vh;
  font-size: 72px;
  color: #fff; }

.board {
  border: 5px solid #b2af9a;
  margin: 50px auto auto;
  width: 500px;
  height: 500px;
  font-size: 30px; }
  .board .header-board {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .board .header-board button {
      font-family: "Architects Daughter", cursive;
      font-size: 18px;
      border: none;
      background: none;
      color: #b2af9a;
      cursor: pointer; }
  .board .title {
    margin-top: 20px;
    width: 100%;
    text-align: center; }
  .board .button {
    color: #fff;
    padding: 15px;
    cursor: pointer;
    border: none; }
  .board .buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 50px; }
  .board .row {
    display: flex;
    width: 400px;
    margin: 0 auto auto; }
    .board .row div {
      width: 150px;
      height: 150px;
      line-height: 150px;
      text-align: center;
      font-size: 110px; }
    .board .row .X {
      color: #c43d27; }
    .board .row .O {
      color: #07bed6; }
  .board .row-one, .board .row-two {
    border-bottom: 3px solid #d1d6cf; }
    .board .row-one .one, .board .row-two .one,
    .board .row-one .two,
    .board .row-two .two {
      border-right: 3px solid #d1d6cf; }
  .board .row-three .one,
  .board .row-three .two {
    border-right: 3px solid #d1d6cf; }
  .board.turn {
    margin-top: 0; }
    .board.turn.turn-red {
      border: 2px solid #c43d27; }
    .board.turn.turn-blue {
      border: 2px solid #07bed6; }

.points {
  display: flex;
  justify-content: flex-end;
  color: #b2af9a;
  font-size: 18px;
  margin: 5px 0 5px 0; }
  .points .point {
    margin: 0 10px 0 10px; }
    .points .point.first {
      color: #c43d27; }
    .points .point.last {
      color: #07bed6; }

.modal {
  width: 300px;
  height: 300px;
  background-color: #b2af9a;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: -115px;
  bottom: 0;
  text-align: center; }
  .modal .content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center; }
  .modal .next {
    background-color: #fff;
    width: 120px;
    box-shadow: 5px 5px 5px #888;
    border: 1px solid #b2af9a;
    margin-top: 10px;
    padding: 5px;
    cursor: pointer; }
  .modal h1 {
    font-size: 30px;
    padding-top: 10px; }
  .modal img {
    width: 100px;
    margin-top: 5px; }
